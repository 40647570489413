<template>
    <div class="relative w-full">
        <span v-if="$slots['content-left']" class="absolute left-3 inset-y-0 flex items-center justify-center z-20">
            <slot name="content-left" />
        </span>

        <input
            v-bind="props"
            :id="id"
            :value="modelValue"
            :class="[
                `py-2 pl-4 pr-6 w-full text-sm md:text-base text-gray-800 bg-transparent rounded border ${hasError ? 'border-sg-red-100' : 'border-sg-grey-200'} focus:outline-none focus:ring-0 ${hasError ? 'focus:border-sg-red-100' : 'focus:border-primary'} peer`,
                $slots['content-left'] ? '!pl-8' : '',
                $slots['content-right'] ? '!pr-8' : '',
            ]"
            placeholder=" "
            @input="onChange" />

        <label
            :for="id"
            :class="[
                `text-sg-grey-300 pr-2 absolute text-sm md:text-base duration-300 transform -translate-y-4 scale-75 top-1.5 z-10 origin-[0] bg-white px-2 peer-focus:px-2 ${!hasError && 'peer-focus:text-primary'} peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1.5 peer-focus:scale-75 peer-focus:-translate-y-4 left-3`,
                $slots['content-left'] && !modelValue ? 'pl-6' : 'pl-2',
            ]">
            {{ placeholder }}
            <span v-if="required && !hideRequired" class="text-red-500">*</span>
        </label>

        <span v-if="$slots['content-right']" class="absolute right-3 inset-y-0 flex items-center justify-center z-20">
            <slot name="content-right" />
        </span>
    </div>
</template>

<script setup lang="ts">
export interface InputProps {
    type?: string;
    disabled?: boolean;
    required?: boolean;
    hideRequired?: boolean;
    id: string;
    name?: string;
    placeholder?: string;
    placeHolderTextClass?: string;
    pattern?: string;
    modelValue?: string | number;
    hasError: false;
}

const props = withDefaults(defineProps<InputProps>(), {
    type: 'text',
    disabled: false,
    required: false,
    name: undefined,
    placeholder: undefined,
    placeHolderTextClass: undefined,
    pattern: undefined,
    modelValue: undefined,
    hasError: false,
});

const emit = defineEmits(['update:modelValue']);

function onChange(event: Event) {
    emit('update:modelValue', (event.target as HTMLInputElement).value);
}
</script>
